import {RouteConfig} from 'react-router-config';
import MainPage from './pages/MainPage';

const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: MainPage,
  }
];

export default routes;
