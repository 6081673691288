import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducer';
import thunkMiddleware from 'redux-thunk';

export default function configureStore(ssrState: any = {}) {
  const middlewares = [
    thunkMiddleware
  ];

  const enhancers = [applyMiddleware(...middlewares)];
  if (typeof window !== 'undefined' && false) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(reducers,
                                 ssrState,
                                 compose(...enhancers));

  return store;
}
