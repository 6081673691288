import React, {useEffect, useState} from 'react';
import {IPropertyFD, TypeCode} from '../typings/FetchData';
import MapperImage from './MapperImage';
import {useDispatch, useSelector} from 'react-redux';
import {actionsOther, selectorsOther} from '../redux/building/buildingReducer';
// @ts-ignore
import ReactCursorPosition from 'react-cursor-position';
import ErrorBoundary from './ErrorBandler';
import MyModal from './MyModal';
// @ts-ignore
import { MapInteractionCSS } from 'react-map-interaction';
import getImgUrl from '../utils/getImgUrl';
import {useWindowSize} from '@react-hook/window-size';

interface ISectionTableProps {
  building: IPropertyFD;
  collapseIndex: number;
}

const rations = {
  540: .4,
  800: .6,
  900: 1,
  1000: 1.1,
  1100: 1.1,
  1200: 1.1,
  1800: 1.1
};
const offset = 0.7;

const SectionTable = ({building, collapseIndex}: ISectionTableProps) => {
  const dispatch = useDispatch();

  const onClick = (evt: any) => {
    if(evt.isAvaliable) {
      if(evt.isSelected) {
        dispatch(actionsOther.removeUnit(+evt.name));
      }
      else {
        dispatch(actionsOther.addUnit(+evt.name));
      }
    }
  };

  return (
    <div className="section-info_table" id={'plans'}>
      <div className="table">
        <div className="container">
          <div className="table-th">
            <span className="th-1">Étage</span>
            <span className="th-2">Surface min</span>
            <span>Surface max</span>
          </div>
        </div>
        {
          Object.values(building.children).map((f, index) => {
            return <ErrorBoundary><Row key={index} floor={f} index={index} collapseIndex={collapseIndex} onClick={onClick} /></ErrorBoundary>
          })
        }
      </div>
      <div className="container down-text">Les lots 101, 103 et 203 seront disponibles en août / septembre 2023.</div>
    </div>
  )
};

interface IRowProps {
  floor: IPropertyFD;
  index: number;
  collapseIndex: number;
  onClick: any;
}
const Row = ({floor, index, collapseIndex, onClick}:IRowProps) => {
  const dispatch = useDispatch();
  const [ww, yy] = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [isZoom, setIsZoom] = useState(false);

  const units = useSelector(selectorsOther.getUnits);
  const neighbors = useSelector(selectorsOther.getNeighbors);
  const sumSurface = useSelector(selectorsOther.getSum(floor.id));
  const isAvailable = useSelector(selectorsOther.isAvailableToChoose(floor.id));
  const minSurface = floor.fields.find(field => field.field.code === TypeCode.minSurface);
  const maxSurface = floor.fields.find(field => field.field.code === TypeCode.maxSurface);

  useEffect(() => {
    if(floor.id === collapseIndex){
      setIsOpen(true);
    }
  }, [collapseIndex]);

  const handleClick = (evt: any) => {
    if(!isAvailable) return;
    onClick(evt);
  };

  const handleOpen = () => {
    setIsOpen(s => !s);
    dispatch(actionsOther.clearUnits());
  };

  useEffect(() => {
    if(isZoom) {
      document.body.style.overflowY = 'hidden';
    }
    else {
      document.body.style.overflowY = 'auto'
    }
  }, [isZoom]);

  return (
    <div id={'plan' + (floor.id)} className={`table-row ${index === 0 ? 'first' : ''}`}>
      {
        isZoom && (
          <MyModal>
            <div className={'zoom-con'}>
              <MapInteractionCSS defaultValue={{
                scale: ww >= 700 ? 0.3 : 0.125,
                translation: {
                  x: ww >= 700 ? ww * 0.2 : 0,
                  y: ww >= 700 ? 0 : yy * 0.2
                }
              }}>
                <img src={getImgUrl(floor.plans)} />
              </MapInteractionCSS>
              <div className={'circle-btn circle-btn-close'} onClick={() => setIsZoom(false)}>
                <img src={require('../assets/img/ic-close-b.svg')} alt=""/>
              </div>
            </div>
          </MyModal>
        )
      }
      <div className="container">
        <div className="table-row_in">
          <div className="table-left">
            <div className="td-1">
              <p>Étage</p>
              {floor.title}
            </div>
            <div className="td-2">
              <p>Surface min</p>
              <span>{minSurface ? minSurface.value : 0} m<sup>2</sup></span>
            </div>
            <div className="td-3">
              <p>Surface max</p>
              <span>{maxSurface ? maxSurface.value : 0} m<sup>2</sup></span>
            </div>
            <div className="td-4">
              <a onClick={handleOpen} className="btn btn-blue">Voir le plan</a>
            </div>
          </div>
        </div>
      </div>
      <section className="section-plan" style={{
         display: isOpen ? 'block' : 'none'
      }}>
        <div style={{position: 'relative'}}>
          <p className="compass-block">Cliquez sur les différentes surfaces pour calculer l'espace de travail
            parfait rien que pour vous.</p>
          <figure className="plan-figure" style={{
            position: 'relative'
          }}>
            {
              !isZoom && (
                <div className={'circle-btn'} onClick={() => setIsZoom(true)}>
                  <img src={require('../assets/img/ic-zoom.svg')} alt=""/>
                </div>
              )
            }
            <ReactCursorPosition>
              <MapperImage
                unit={floor}
                fillColor={'rgba(4, 70, 96, .4)'}
                isPreFillColor
                maxWidth={1400}
                ratios={rations}
                neighbors={neighbors}
                onClick={handleClick}
                selectedItems={units}
                offset={offset}
                tooltip={isAvailable ? 'Cliquer pour sélectionner' : 'Surface non disponible'}
                isTooltip
              />
            </ReactCursorPosition>
          </figure>
          <div className="plan-info">
            <div>
              <div className={'legend'}>
                <div className={'legend-con'}>
                  <div className={'box box-blue'}></div>
                  <p>disponible</p>
                </div>
                <div className={'legend-con'}>
                  <div className={'box box-grey'}></div>
                  <p>loué</p>
                </div>
              </div>
              <div className={'con-plan-info'}>
                <div className="plan-info_label">
                  <div className="plan-info_text">
                    <span>Surface totale approximative cumulée</span>
                    <span className="plan-info_number">{sumSurface.toFixed(2)} m<sup>2</sup></span>
                  </div>
                </div>
                {sumSurface !== 0 && <a href="#contact" className="btn btn-blue">Je suis intéressé(e)</a> }
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default SectionTable;
