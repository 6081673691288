export enum TypeProperty {
  building = 'building',
  floor = 'floor',
  unit = 'unit',
}

export enum TypeCode {
  minSurface = 'minSurface',
  maxSurface = 'maxSurface',
  surface = 'surface',
  order = 'order',
}

export interface IBaseProperty {
  id: number
  isAvaliable: boolean
  title: string
  status: {
    code: string
  } | null
}
export interface IPropertyFD extends IBaseProperty {
  parent: IBaseProperty | null
  plans: IPlanFD[];
  type: {code: TypeProperty, description: string}
  fields: IField[]
  neighbours: IBaseProperty[]
  viewCoordinates: {
    id: number
    type: string
    coordinates: number[]
  }[]
  children: {
    [id: string]: IPropertyFD
  };
}

export interface IPlanFD {
  id: string
  name: string
  ord: string
}

export interface IField {
  id: number
  value: string
  field: {
    code: TypeCode
    title: string
    type: string
  }
}

export interface IFormData {
  interested_in: boolean;
  firstName: string;
  company: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
}
