import {instanceWD, instance} from './instance';
import {AxiosResponse} from 'axios';
import {IFormData, IPropertyFD} from '../typings/FetchData';

const service = {
    getProperty: (id: number) => {
      return customFetch(() => instance.get('properties/' + id));
    },
    getAllProperties: () => {
        return customFetch<IPropertyFD[]>(() => instance.get('properties/'));
    },
    getPropertyChildren: (id: number) => {
      return customFetch(() => instance.get(`properties/${id}/children`));
    },
    contactForm: (data: IFormData, floorData: any) => {
      const formData = new FormData();
      formData.append('project', 'montblanc');
      formData.append('source', 'landing');
      formData.append('action', 'contact_request');
      formData.append('message', data.message || '');
      formData.append('contact[first_name]', data.firstName);
      formData.append('contact[last_name]', data.lastName);
      formData.append('contact[email]', data.email);
      formData.append('contact[phone]', data.phone);
      formData.append('contact[company]', data.company);
      formData.append('property[interested_in]', data.interested_in + '');
      formData.append('property[floor]', floorData.floor);
      formData.append('property[lot]', floorData.units);

      const config: any = {
        method: 'post',
        url: 'submitRequest',
        data : formData,
        redirect: 'follow'
      };

      return customFetch(() => instanceWD(config));
    },
    brochureDownload: (data: IFormData) => {
      const formData = new FormData();
      formData.append('project', 'montblanc');
      formData.append('source', 'landing');
      formData.append('action', 'brochure_download');
      formData.append('contact[first_name]', data.firstName);
      formData.append('contact[last_name]', data.lastName);
      formData.append('contact[email]', data.email);
      formData.append('contact[phone]', data.phone);


      const config: any = {
        method: 'post',
        url: 'submitRequest',
        data : formData,
        redirect: 'follow'
      };

      return customFetch(() => instanceWD(config));
  }
};

const customFetch = async <T>(func: () => Promise<AxiosResponse<T>>) => {
  try {
    const res = await func();
    return {
      success: true,
      data: res.data,
      code: res.status,
    };
  } catch (e) {
    console.error({e});
    if (e.response && e.response.data && e.response.data.message) {
      throw {
        success: false,
        data: e.response.data.message,
        code: e.response.status,
      };
    }
    throw {
      success: false,
      data: 'error',
      code: 500,
    };
  }
};

export default service;
