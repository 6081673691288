import axios from 'axios';
import config from '../config';

const instance = axios.create({
  baseURL: config.baseURL,
  //withCredentials: true
});

instance.interceptors.request.use(value => {
  value.headers = {
    ...value.headers,
    'p-api-key': config.api_key
  }
  return value;
})

const instanceWD = axios.create({
  baseURL: config.baseURLWD,
  //withCredentials: true
});

export {instance, instanceWD};
