import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import { HelmetProvider } from 'react-helmet-async'
import { Base64 } from 'js-base64';
import configureStore from './redux/store';
import './assets/css/normalize.css';
import './assets/css/owl.carousel.css';
import './assets/css/select2.min.css';
import './assets/css/styles.css';
import "react-responsive-modal/styles.css";
import './assets/css/other.scss';

const initialState = ((window as any).DATA !== null && (window as any).DATA !== '{{WINDOW_DATA}}')
  ? Base64.decode((window as any).DATA) : '{}';

const parsedInitialState = JSON.parse(initialState);
const store = configureStore(parsedInitialState);

const name = initialState === '{}' ? 'render' : 'hydrate';

ReactDOM[name](
  <HelmetProvider>
      <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);
