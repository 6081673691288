const useValidation = () => {
  const validation = {
    email: {
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Mauvais format d’adresse mail'
      }
    },
    required: {
      required: 'Veuillez remplir les champs obligatoires'
    },
  };

  return validation;
};

export default useValidation;
