import React, {useState} from 'react';
import useValidation from '../utils/validation';
import {useForm} from 'react-hook-form';
import Input from './Input';
import Modal from 'react-responsive-modal';
import service from '../services/service';

interface IDownloadBrochureProps {

}

const DownloadBrochure = ({}: IDownloadBrochureProps) => {
  const validation = useValidation();
  const {handleSubmit,errors, register, reset, watch} = useForm();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data: any) => {
    const handle = async () => {
      setIsLoading(true);
      try {
        const res = await service.brochureDownload(data);
        if(res.success) {
          reset();
          setOpen(false)
          setOpen2(true);
        }
      }
      catch (e) {
        console.log({e})
      }
      finally {
        setIsLoading(false);
      }
    };
    handle();
  };


  return (
    <React.Fragment>
    <a className="brochure-label" onClick={() => setOpen(true)}><span>Brochure</span></a>
      <Modal open={open2} onClose={() => setOpen2(false)} showCloseIcon={false}>
        <div id="thankyou">
          <div className="modal-body">
            <span className="modal-logo"><img src={require('../assets/img/logo.svg')} /></span>
            <div className="modal-content">
              <article className="brochure-thankyou">
                <button onClick={() => setOpen2(false)} className="modal-close">&nbsp;</button>

                <h3>Merci !</h3>
                <p className="subtitle">Merci votre message a bien été envoyé !<br/>
                  Vérifiez vos emails. Si vous ne recevez rien sous 10 minutes, vérifiez vos courriers indésirables.
                </p>
              </article>
            </div>
          </div>
        </div>

      </Modal>
      <Modal open={open} onClose={handleClose} showCloseIcon={false}>
        <div>
          <div className="modal-body">
            <span className="modal-logo"><img src={require('../assets/img/logo.svg')} /></span>
            <div className="modal-content">
              <div className="brochure-form">
                <button className="modal-close" onClick={() => setOpen(false)}>&nbsp;</button>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="modal-form">
                    <h3>Télécharger la brochure</h3>
                    <div className="form-block">
                      <Input label={'Nom'} name={'lastName'} ref={register(validation.required)} errors={errors} />
                      <Input label={'Prénom'} name={'firstName'} ref={register(validation.required)} errors={errors} />
                      <Input label={'Numéro de téléphone'} name={'phone'} ref={register(validation.required)} errors={errors} />
                      <Input label={'Email'} name={'email'} ref={register(validation.required)} errors={errors} />
                    </div>
                    <div className="form-item form-checkbox">
                      <input type="checkbox" name="tos" hidden id="checkbox2" ref={register(validation.required)} />
                      <label htmlFor="checkbox2">J’ai lu et j’accepte la <a
                        href="https://wild-dots.com/fr/mentions-legales/" target="_blank">politique de
                        confidentialité</a> de ce site*</label>
                    </div>
                  </div>

                  <div className="modal-buttons">
                    <input type="hidden" name="action" value="brochure_download" />
                    <button className={`btn ${!watch('tos') || isLoading ? 'btn-disabled' : ''}`} disabled={!watch('tos') || isLoading}>Recevoir la brochure</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
};

export default DownloadBrochure;
