import {IPlanFD} from '../typings/FetchData';
import config from '../config';

const getImgUrl = (p: string | IPlanFD[]) => {
  let name = '';

  if(Array.isArray(p)) {
    name = p[0] ? p[0].name : '';
  }
  else {
    name = p;
  }

  return config.baseURL_IMG + name
};

export default getImgUrl;
