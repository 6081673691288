import { combineReducers } from 'redux';
import {IBuildingState} from './building/buildingTypes';
import buildingReducer from './building/buildingReducer';

export interface RootState {
  building: IBuildingState;
}

export default combineReducers({
  building: buildingReducer
});
