import React, {useEffect, useState} from 'react';
import service from '../services/service';
import {IPropertyFD, TypeProperty} from '../typings/FetchData';
import SectionTable from './SectionTable';
import MapperImage from './MapperImage';
import {useDispatch} from 'react-redux';
import {actionsOther} from '../redux/building/buildingReducer';
// @ts-ignore
import ReactCursorPosition from 'react-cursor-position';
import ErrorBoundary from './ErrorBandler';

interface IBuildCanvasProps {

}

const fillColor = "rgba(4, 70, 96, .7)";
const rations = {
  540: .3,
  640: .5,
  740: .5,
  840: .7,
  940: .7,
  1040: 1,
};

const availableStatus = ['available']
const BuildCanvas = ({}: IBuildCanvasProps) => {
  const dispatch = useDispatch();
  const [building, setBuilding] = useState(null as IPropertyFD | null);
  const [index, setIndex] = useState(-1);

  useEffect(() => {

    service.getAllProperties()
      .then(res => {
        if(res.success) {
          const building: IPropertyFD = res.data.find(p => p.type.code === TypeProperty.building)!;
          res.data.forEach(p => {
            p.isAvaliable = p.status ? availableStatus.some(a => a === p.status!.code) : false;
          });

          building.children = { };
          res.data.filter(p => p.type.code ===TypeProperty.floor).forEach(p => {
            if(p.isAvaliable) {
              p.children = {}
              building.children[p.id] = p;
            }
          });

          res.data.filter(p => p.type.code ===TypeProperty.unit).forEach(p => {
            if(building.children[p.parent!.id]) {
              building.children[p.parent!.id].children[p.id] = p;
            }
          });

          dispatch(actionsOther.setData({
            building,
            buildings: res.data
          }));
          setBuilding(building);
        }
      })
  }, []);

  const onClick = (evt: any) => {
    setIndex(+evt.name);
    const el: any = document.querySelector('#plan' + evt.name);
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }

  if(!building) return null;

  return <div id={building!.title}>
    <ErrorBoundary>
    <ReactCursorPosition>
      <MapperImage unit={building} fillColor={fillColor} ratios={rations} onClick={onClick} isTooltip={true} tooltip={'Cliquez pour voir les plans'}/>
    </ReactCursorPosition>
    </ErrorBoundary>
    <SectionTable building={building} collapseIndex={index} />
  </div>
};

export default BuildCanvas;
