import React, {useRef} from 'react';

interface IHeaderProps {

}

const Header = ({}: IHeaderProps) => {
  const refBtn = useRef(null as null | any);

  const handleClick = (id: string) => {
    const el: any = document.querySelector(id);
    el.scrollIntoView({block: "center", behavior: "smooth"});
    if(refBtn.current) {
      refBtn.current.click();
    }
  };


  return (
    <header id="header">
      <div className="header-container">
        <a href="#main" className="header-logo"><img src={require('../assets/img/logo.svg')}/></a>
        <nav className="header-menu">
            <span ref={refBtn} className="btn-menu">
                <i></i>
                <i></i>
                <i></i>
            </span>
          <ul>
            <li><a onClick={() => handleClick("#second")}>À propos</a></li>
            <li><a onClick={() => handleClick("#plans")}>PlanS des surfaces</a></li>
            <li><a onClick={() => handleClick("#galerie")}>Galerie</a></li>
            <li><a onClick={() => handleClick("#map")}>Localisation</a></li>
            <li><a onClick={() => handleClick("#contact")} className="header-contact">Contact</a></li>
          </ul>
        </nav>
      </div>
    </header>
  )
};

export default Header;
