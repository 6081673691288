import React, {useMemo, useState} from 'react';
import {IPropertyFD} from '../typings/FetchData';
import {useWindowSize} from '@react-hook/window-size';
import getImgUrl from '../utils/getImgUrl';
// @ts-ignore
import ImageMapper from 'react-image-mapper';

interface IMapperImageProps {
  unit: IPropertyFD;
  fillColor: string;
  maxWidth?: number;
  ratios: any;
  selectedItems?: number[];
  onClick?: any;
  isTooltip?: boolean;
  position?: any;
  offset?: number;
  isPreFillColor?: boolean;
  tooltip?: string;
  neighbors?: number[];
}

const getRatio = (width: number, ratios: any) => {
  const findRatio = Object.keys(ratios)
    .find(r => width <= +r);

  return findRatio ? ratios[findRatio] : 1;
};

const MapperImage = ({fillColor, unit, maxWidth = 4000, ratios, selectedItems = [], neighbors = [], onClick, isTooltip = false, position, offset  = 1, isPreFillColor = false, tooltip}: IMapperImageProps) => {
  const [naturalSizeImg, setNaturalSizeImg] = useState({w: 1, h: 1} as {w: number, h: number});
  const [ww, hh] = useWindowSize();
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const [isAvaliableToolTip, setIsAvaliableToolTip] = useState(false);

  const of = (ww <= 600 ? 1 : offset);
  let widthWindow = (ww > maxWidth ? maxWidth : ww) * of;
  let heightWindow = hh * of;

  const MAP = useMemo(() => {
    if(!unit) {
      return null;
    }
    const res = {
      name: unit.title,
      areas: Object.values(unit!.children).map(c => c.viewCoordinates[0] ? c.viewCoordinates[0].coordinates : []).map((c, i) => {
        const item = Object.values(unit!.children)[i];
        const isSelected = selectedItems.some(s => s === item.id);
        let isAvaliable = item.isAvaliable;
        let preFillColor: any = isPreFillColor ? fillColor : null;

        if(neighbors.length > 0 && !isSelected) {
          isAvaliable = neighbors.some(n => n === item.id);
          if(!isAvaliable) {
            return null;
          }
        }
        else {
          if(!isAvaliable) {
            preFillColor = 'rgba(0, 0, 0, .2)'
          }
        }
        if(isSelected) {
          preFillColor = "rgba(4, 70, 96, .8)"
        }
        return { name: (item.id) + '', shape: "poly", coords: c, fillColor: isAvaliable && !isSelected ? fillColor : 'transparent', preFillColor, isAvaliable, isSelected  }
      })
    };

    res.areas = res.areas.filter(a => !!a).map(a => {
      if(!a) return a;
      a.coords = a.coords.map((c, i) => {
        if((i + 1) % 2 !== 0) {
          const width = widthWindow || document.documentElement.clientWidth;
          return c * width / naturalSizeImg.w;
        }
        const height = heightWindow || document.documentElement.clientHeight;
        return c * height * getRatio(widthWindow + of, ratios) / naturalSizeImg.h;
      });
      return a;
    });

    return res;
  }, [widthWindow, heightWindow, naturalSizeImg, unit, selectedItems, of]);

  const handleLoad = () => {
    const el: any = document.querySelector(`#uniq${unit!.id} img`)!;
    if(el) {
      setNaturalSizeImg({
        w: el.naturalWidth,
        h: el.naturalHeight,
      })
    }
  };

  return (
    <div id={'uniq' + unit.id} className={'image-mapper'}>
      {
        isOpenTooltip && isTooltip && (
          <span className="floor-bubble"  style={{
            top: position.y - 110,
            left: position.x - 150
          }}>{isAvaliableToolTip ? 'Surface non disponible' : tooltip}</span>
        )
      }
      <ImageMapper
        onMouseEnter={(evt: any) => {
          if(evt.isAvaliable) {
            setIsAvaliableToolTip(false);
          }
          else {
            setIsAvaliableToolTip(true);
          }
          setIsOpenTooltip(true)
        }} onMouseLeave={() => {
        setIsOpenTooltip(false)
      }}
        onClick={onClick}
        onLoad={handleLoad}
        active={true}
        src={getImgUrl(unit.plans)}
        map={MAP} width={widthWindow }
        height={(heightWindow!) * getRatio(widthWindow, ratios) - ((selectedItems.length + 1) / 10)}
      />
    </div>
  )
};

export default MapperImage;
