import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import Input from './Input';
import useValidation from '../utils/validation';
import service from '../services/service';
import Modal from 'react-responsive-modal';
import {useSelector} from 'react-redux';
import {selectorsOther} from '../redux/building/buildingReducer';

const SectionContact = () => {
  const validation = useValidation();
  const {register, handleSubmit, errors, watch, reset} = useForm();
  const floorData = useSelector(selectorsOther.getFloorAndLots);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
      const handle = async () => {
        setIsLoading(true);
        try {
          const res = await service.contactForm(data, floorData);
          if(res.success) {
            reset();
            setOpen(true)
          }
        }
        catch (e) {
          console.log({e})
        }
        finally {
          setIsLoading(false);
        }
      }
      handle();
  };



  return (
    <section className="section-contact" id="contact">
      <Modal open={open} onClose={handleClose} showCloseIcon={false}>
        <div id="thankyou">
          <div className="modal-body">
            <span className="modal-logo"><img src={require('../assets/img/logo.svg')} /></span>
            <div className="modal-content">
              <article className="brochure-thankyou">
                <button onClick={handleClose} className="modal-close">&nbsp;</button>

                <h3>Merci !</h3>
                <p className="subtitle">Merci votre message a bien été envoyé !<br/>
                  Vérifiez vos emails. Si vous ne recevez rien sous 10 minutes, vérifiez vos courriers indésirables.
                </p>
              </article>
            </div>
          </div>
        </div>

      </Modal>
      <div className="contact-block">
        <h2>Je suis <span className="inline-block">intéressé(e) !</span></h2>
        {
          floorData.sum !== 0 && (
            <div className={'contact-info'}>
              <p className="question"><strong>Étage:</strong> {floorData.floor}</p>
              <p className="question"><strong>Bureau:</strong> {floorData.units}</p>
              <p className="question"><strong>Surface:</strong> {floorData.sum.toFixed(2)}</p>
            </div>
          )
        }
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="contact-form_left">
            <p className="question">Vous êtes intéressés par :</p>
            <div className="form-radio_block">
              <div className="form-radio_item">
                <input type="radio" id="radio1-1" name="interested_in" value="Une surface d'activité" hidden ref={register}/>
                <label htmlFor="radio1-1">Une surface <span className="medium-bold">d'activité</span></label>
              </div>
              <div className="form-radio_item">
                <input type="radio" id="radio1-2" name="interested_in" value="Une arcade commerciale" hidden ref={register}/>
                <label htmlFor="radio1-2">Une arcade <span className="medium-bold">commerciale</span><br/>(Disponible dès le 1er trimestre 2023)</label>
              </div>
            </div>
            <p className="question">Laissez-nous un message :</p>
            <div className="form-message">
              <label className="form-label">Message</label>
              <textarea name="message" ref={register}></textarea>
            </div>
          </div>
          <div className="contact-form_right">
            <Input name={'lastName'} label={'Nom'} ref={register(validation.required)} errors={errors}/>
            <Input name={'firstName'} label={'Prénom'} ref={register(validation.required)} errors={errors}/>
            <Input name={'company'} label={'Société'} ref={register(validation.required)} errors={errors}/>
            <Input name={'email'} label={'Email'} ref={register(validation.required)} errors={errors}/>
            <Input name={'phone'} label={'Téléphone'} ref={register(validation.required)} errors={errors}/>
            <div className="form-checkbox">
              <input type="checkbox" name="checkbox" hidden id="tos" ref={register}/>
              <label htmlFor="tos">J’ai lu et j’accepte la politique de confidentialité de ce site </label>
            </div>
            <p className="btn-row">
              <button type="submit"  className={`btn btn-blue ${!watch('checkbox') || isLoading ? 'btn-disabled' : ''}`} disabled={!watch('checkbox') || isLoading}>ENVOYER</button>
            </p>
          </div>
        </form>
      </div>
      <div className="contact-form_thankyou hide">
        <h2>Merci !</h2>
        <p className="text">Merci votre message a bien été envoyé !</p>
        <span className="icon-check"></span>
      </div>
    </section>
  )
};

export default SectionContact;
