import {CreatorReducer} from '../base/base';
import {IBuildingActions, IBuildingState} from './buildingTypes';
import {RootState} from '../reducer';
import {TypeCode} from '../../typings/FetchData';

const init: IBuildingState = {
  units: [],
  building: null,
  buildings: []
};

const creator = new CreatorReducer<IBuildingActions, IBuildingState>('building');
creator.addAction('addUnit', (state, action) => {
  return {...state, units: [...state.units, action.payload]}
});

creator.addAction('removeUnit', (state, action) => {
  let units = state.units.filter(id => id !== action.payload);
  if(state.units.length >= 3) {
    for (let unit of units) {
      const neighbors = state.buildings.find(b => b.id === unit)!.neighbours.map(n => n.id);
      if(!neighbors.some(n => units.some(u => u === n))) {
        return state;
      }
    }
  }
  return {...state, units}
});

creator.addAction('setData', (state, action) => {
  return {...state, ...action.payload}
})

creator.addAction('clearUnits', (state, action) => {
  return {...state, units: []}
});
const actionsOther = creator.createActions();

const selectorsOther = {
  getUnits: (state: RootState) => state.building.units,
  getSum: (idFloor: number) => (state: RootState) => {
    const building = state.building.building;
    if(building === null) return 0 ;
    const units = state.building.units;

    const floor = Object.values(building.children).find(f => f.id == idFloor)!;
    return Object.values(floor.children).filter(unit => units.some(u => u == unit!.id)).reduce((a,b) => {
      const findItem = b.fields.find(f => f.field.code === TypeCode.surface);

      return a + (findItem ? +findItem.value : 0)
    }, 0)

  },
  isAvailableToChoose: (idFloor: number) => (state: RootState) => {
    const building = state.building.building;
    if(building === null) return false;
    const units = state.building.units;

    if(units.length === 0) return true;

    const floor = Object.values(building.children).find(f => f.id == idFloor)!;
    return Object.values(floor.children).some(u => u.id === units[0]);
  },
  getFloorAndLots: (state: RootState) => {
    const units = state.building.buildings.filter(b => state.building.units.some(u => u === b.id));

    if(units.length === 0) {
      return {
        floor: '',
        units: '',
        sum: 0
      }
    }

    return {
      floor: units[0].parent!.title,
      units: units.map(u => u.title).join(', '),
      sum: selectorsOther.getSum(units[0].parent!.id)(state)
    }
  },
  getNeighbors: (state: RootState) => {
    const units = selectorsOther.getUnits(state);
    if(units.length === 0) {
      return [] as number[]
    }

    return (state.building.buildings.filter(b => units.some(u => u === b.id))
      .map(b => b.neighbours.map(n => n.id)) as any).flat() as number[]
  }
};

export {actionsOther, selectorsOther}
export default creator.createReducer(init);
