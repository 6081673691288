import React from 'react';
import Header from '../сomponents/Header';
import BuildCanvas from '../сomponents/BuildCanvas';
import SectionContact from '../сomponents/SectionContact';
import DownloadBrochure from '../сomponents/DownloadBrochure';

const logo = require('../assets/img/logo.svg');

const MainPage = () => {

  const handleClick = () => {
    const el: any = document.querySelector('#second');
    el.scrollIntoView({block: "center", behavior: "smooth"});
  };

  return <div>
    <Header/>
    <main className="section-main" id="main">
      <div className="section-main_container">
        <p className="slogan">Votre activité professionnelle au cœur de Genève</p>
        <a href="#contact" className="btn">Contactez-nous !</a>
        <a onClick={handleClick} className="arrow"></a>
      </div>
    </main>

    <section className="section-advert" id="second">
      <div className="container">
        <figure className="advert-pic"><img src={require('../assets/img/pic-adv.jpg')}/></figure>
        <span className="block-logo"><img src={logo}/></span>
        <article className="advert-content2">
          <p className="title">Rue du Mont-Blanc 7</p>
          Magnifiques surfaces administratives dans un immeuble fraîchement rénové et idéalement situé.<br/>
          En plein cœur de Genève, sur l’une des artères les plus fréquentées de la ville, le bâtiment se situe en
          prolongement du pont du Mont-Blanc.<br/>
          Il bénéficie de la proximité immédiate de nombreux commerces, restaurants, hôtels 5 étoiles
        </article>
      </div>
    </section>

    <section className="section-slider" id="galerie">
      <span className="block-logo"><img src={require('../assets/img/logo_d.svg')}/></span>

      <div className="owl-carousel owl-theme gallery-slider">
        <div className="item">
          <figure className="gallery-pic"><img src={require('../assets/img/slide1.jpg')}/></figure>
          <article className="gallery-text">
            <p className="title">Le bâtiment propose les surfaces suivantes :</p>
            3’200 m² de surfaces administratives réparties sur 6 étages<br/>
            140 m² d’arcade commerciale en rez-de-chaussée<br/>
            Les 3’200 m² répartis par plateau d’environ 680 m² (divisibles dès 140 m²) ainsi que l’arcade de 140 m²
            offrent une belle flexibilité d’aménagement<br/>
            L’arcade est divisible dès 50m²
          </article>
        </div>
        <div className="item">
          <figure className="gallery-pic"><img src={require('../assets/img/slide2.jpg')}/></figure>
          <article className="gallery-text">
            <p className="title">Les surfaces sont proposées aux loyers suivants :</p>
            CHF 650.-/m²/an pour les bureaux (frais accessoire et air rafraîchit compris)<br/>
            + Charge CHF 25.-/m²/an<br/>
            CHF 1’100.-/m²/an pour l’arcade (frais accessoire et air rafraîchit compris)<br/>
            + Charge CHF 25.-/m²/an<br/>
            Les bureaux sont divisibles dès 140m²
          </article>
        </div>
        <div className="item">
          <figure className="gallery-pic"><img src={require('../assets/img/imgfile.jpg')}/></figure>
          <article className="gallery-text">
            <p className="title">Le bâtiment propose les surfaces suivantes :</p>
            3’200 m² de surfaces administratives réparties sur 6 étages<br/>
            140 m² d’arcade commerciale en rez-de-chaussée<br/>
            Les 3’200 m² répartis par plateau d’environ 680 m² (divisibles dès 140 m²) ainsi que l’arcade de 140 m²
            offrent une belle flexibilité d’aménagement<br/>
            L’arcade est divisible dès 50m²
          </article>
        </div>
        <div className="item">
          <figure className="gallery-pic"><img src={require('../assets/img/backbone.jpg')}/></figure>
          <article className="gallery-text">
            <p className="title">Les surfaces sont proposées aux loyers suivants :</p>
            CHF 650.-/m²/an pour les bureaux (frais accessoire et air rafraîchit compris)<br/>
            + Charge CHF 25.-/m²/an<br/>
            CHF 1’100.-/m²/an pour l’arcade (frais accessoire et air rafraîchit compris)<br/>
            + Charge CHF 25.-/m²/an<br/>
            Les bureaux sont divisibles dès 140m²
          </article>
        </div>
      </div>
      <a href="#contact" className="btn btn-white">Contactez-nous !</a>
    </section>

    <section className="section-view">&nbsp;</section>

    <section className="map-section" id="map">
      <aside className="map-side">
        <h2>Situation</h2>
        <div className="map-info foot">
          <p>À QUELQUES MINUTES À PIED</p>
          <ul>
            <li>5 min - Gare Cornavin</li>
            <li>8 min - Centre ville</li>
            <li>3 min - Manor</li>
          </ul>
        </div>
        <div className="map-info car">
          <p>À QUELQUES MINUTES EN VOITURE</p>
          <ul>
            <li>15 min - Genève Aéroport</li>
            <li>9 min - Autoroute (A1)</li>
          </ul>
        </div>
      </aside>
      <figure className="map-pic"></figure>
    </section>

    <section className="section-info">
      <div className="section-info_title">
        <h2>Plans des surfaces</h2>
      </div>
      <BuildCanvas/>
    </section>

    <SectionContact/>

    <footer className="footer" id="footer">
      <span className="block-logo"><img src={require('../assets/img/logo_d.svg')}/></span>
      <div className="footer-container">
        <div className="footer-contact">
          <p className="footer-title">L'Équipe Commerciale</p>
          <div className="footer-left">
            <ul className="footer-team">
              <li>
                <figure className="footer-team_pic"><img src={require('../assets/img/gregoire-marra.png')}/></figure>
                <div className="footer-team_info">
                  <span className="footer-team_name">Monsieur Grégoire MARRA</span>
                  <ul className="footer-team_contact">
                    <li>Contact pour les surfaces de bureaux</li>
                    <li><a href="mailto:marra@comptoir-immo.ch">marra@comptoir-immo.ch</a></li>
                    <li>+41 22 319 88 17</li>
                  </ul>
                </div>
              </li>
              <li>
                <figure className="footer-team_pic"><img src={require('../assets/img/chiste-fabiola.png')}/></figure>
                <div className="footer-team_info">
                  <span className="footer-team_name">Madame Fabiola CHRISTE</span>
                  <ul className="footer-team_contact">
                    <li>Contact pour l’arcade</li>
                    <li><a href="mailto:christe@comptoir-immo.ch">christe@comptoir-immo.ch</a></li>
                    <li>+41 22 319 89 24</li>
                  </ul>
                </div>
              </li>
              <li>
                <figure className="footer-team_pic"><img src={require('../assets/img/gaetan-bellec.png')}/></figure>
                <div className="footer-team_info">
                  <span className="footer-team_name">Monsieur Gaëtan BELLEC</span>
                  <ul className="footer-team_contact">
                    <li>CONTACT POUR L'ARCADE</li>
                    <li><a href="mailto:gbellec@comptoir-immo.ch">gbellec@comptoir-immo.ch</a></li>
                    <li>+41 22 319 89 22</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-right_contact">
            <figure className="logo-ci"><img src={require('../assets/img/logo-ci.svg')}/></figure>
            <address className="footer-address">
              <p>Comptoir Immobilier SA</p>
              Cours de Rive 7<br/>
              1204 Genève, Suisse Case Postale 3753
            </address>
            <ul className="footer-contact_list">
              <li className="email"><a href="mailto:commercial@comptoir-immo.ch">commercial@comptoir-immo.ch</a></li>
              <li className="phone"><a href="tel:+41223198817">+41 22 319 88 17</a></li>
              <li className="globe"><a href="https://comptoir-immo.ch" target="_blank">comptoir-immo.ch</a><br/><a
                href="https://ci-montblanc7.ch" target="_blank">ci-montblanc7.ch</a></li>
            </ul>
          </div>
          <div className="helvetia-block">
            <p>Un projet de Helvetia Assurances</p>
            <img src={require('../assets/img/loho-helv.png')}/>
          </div>
        </div>
      </div>
      <div className="footer-official">
        <div className="container">
          <div className="footer-official_left">
            <a href="https://wild-dots.com/fr/mentions-legales/" target="_blank">Mentions légales:</a> <span>Ce site internet et son contenu sont non contractuels. Seul l'acte notarié fait foi.</span>
          </div>
          <div className="footer-official_right">
            <span>Ci-montblanc7.ch est développé par</span> <a href="https://wild-dots.com/fr/" className="wd-logo"
                                                               target="_blank"><img
            src={require('../assets/img/wd_logo.png')}/></a>
          </div>
        </div>
      </div>
    </footer>


    <DownloadBrochure/>
  </div>
};

export default MainPage;
