import React from 'react';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  errors?: any;
}

const Input = React.forwardRef(({name, label, type, errors, ...rest}: IInputProps, ref: any) => {
  const error = errors && errors[name] ? errors[name].message : '';

  return (
    <div className="form-item">
      <label>{label}</label>
      <input type={type} name={name} ref={ref} />
      {error && <p className="error-input">{error}</p>}
    </div>
  )
});


export default Input;
