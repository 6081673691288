import React from 'react';
import {Router} from 'react-router-dom';
import {isBrowser} from './utils/isBrowser';
import {createBrowserHistory, createMemoryHistory} from 'history';
import routes from './routes';
import {renderRoutes} from 'react-router-config';
import ProviderScrollToTop from './context/ScrollToTopContext';

let history: any = {};
history = !isBrowser ? createMemoryHistory() : createBrowserHistory();

function App() {
  const Content = () => {
    return (
      <div className="App">
        {renderRoutes(routes)}
      </div>
    );
  };

  return (
    <Router history={history}>
      <ProviderScrollToTop>
          <Content/>
      </ProviderScrollToTop>
    </Router>
  );
}

export default App;
